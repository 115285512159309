<template>
  <div id="app" style="padding:15px; overflow:auto; width:calc(100% - 30px)">
    <loading :loading="loading" text=""></loading>
    <el-form ref="form" :model="message"  label-width="100px">
      <el-form-item label="标题" :required="true">
        <el-input type="text" v-model="message.title" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="内容" :required="true">
        <el-input type="textarea" :rows="4" v-model="message.content" placeholder="请输入"></el-input>
      </el-form-item>
      <el-form-item label="推送目标" :required="true">
        <el-radio v-model="message.targetType" :label="targetTypeEnum.IDS">指定ID</el-radio>
        <el-radio v-model="message.targetType" :label="targetTypeEnum.RANGE">指定范围</el-radio>
      </el-form-item>
      <el-form-item label="学员ID" :required="true" v-show="message.targetType == targetTypeEnum.IDS">
        <el-input type="textarea" :rows="4" v-model="message.studentIds" placeholder="请输入学员ID，每行一个ID"></el-input>
      </el-form-item>
      <el-form-item label="学员ID" :required="true" v-show="message.targetType == targetTypeEnum.RANGE">
        <el-input type="number" v-model="message.startId" placeholder="起始id" style="width: 100px"></el-input> - <el-input type="number" v-model="message.endId" placeholder="结束id" style="width: 100px"></el-input>
      </el-form-item>
      <el-form-item style="display: none">
        <el-button @click="submit()" id="layuiadmin-app-form-submit">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import {QuestionTypeEnum, request, TipEnum, UrlEnum,TargetTypeEnum} from "../../../public/js/common-vue";
import Loading from "@/components/Loading";

export default {
  name: "PushSave",
  components: {Loading},
  data() {
    return {
      loading: false,
      id:"",
      targetTypeEnum:TargetTypeEnum,
      message:{
        title:"",
        content:"",
        studentIds:"",
        targetType:TargetTypeEnum.IDS,
        startId:0,
        endId:0,
      }
    }
  },
  methods:{
    checkIds(ids){
      for(let i=0; i<ids.length; i++){
        const c = ids[i];
        if(c != '0' && c != '1' && c != '2' && c != '3' && c != '4' && c != '5' && c != '6' && c != '7' && c != '8' && c != '9' && c != '\n' ){
          return false;
        }
      }
      return true;
    },
    submit(){
      if(this.message.title == ""){
        this.showMsgError("请输入标题");
        return;
      }
      if(this.message.content == ""){
        this.showMsgError("请输入内容");
        return;
      }
      if(this.message.targetType == this.targetTypeEnum.IDS && this.message.studentIds.trim() == ""){
          this.showMsgError("请输入学员ID");
          return;
      }
      this.message.studentIds = this.message.studentIds.replaceAll(' ','');
      if(this.checkIds(this.message.studentIds) == false){
        this.showMsgError("请输入正确的学员ID");
        return;
      }
      this.message.studentIds = this.message.studentIds.replaceAll('\n',',')
      console.log(this.message.studentIds);
      if(this.message.targetType == this.targetTypeEnum.RANGE){
        this.message.target = this.message.startId + "-" + this.message.endId
      }
      this.$http({
        method: 'post',
        url: UrlEnum.PUSH_MESSAGE,
        data: this.message,
      })
      .then((res) => {
        this.showMsgSuccess(TipEnum.SUBMIT_SUCCESS);
        const index = parent.layer.getFrameIndex(window.name); //先得到当前iframe层的索引
        setTimeout(function () {
          parent.layui.table.reload('tableData'); //重载表格
          parent.layer.close(index); //再执行关闭
        }, 800);
      })
      .catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    },
  },
  mounted() {
    if(request("id") != null && request("id") != ""){
      this.id = request("id");
      this.loading=true;
      this.$http({
        method: "get",
        url: UrlEnum.PUSH_MESSAGE + "/" + this.id,
      }).then((res) => {
        this.loading=false;
        this.message = res.data;
        console.log(this.message);
       }).catch((res) => {
        this.loading=false;
        if(res.response != null){
          const data = res.response.data;
          this.showMsgError(data.message);
        }
      });
    }
  },
}
</script>

<style scoped>

</style>
